import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'heroicons-react';

import Filters from '../../shared/Filters';

const useQuery = () => new URLSearchParams(useLocation().search);

const UserList = ({
    users,
    columnHeadings,
    defaultSortKey,
    identifier,
    handleUserClick,
    customSortFunctions = {},
    filterOptions = [],
}) => {
    const query = useQuery();
    const location = useLocation();
    const navigate = useNavigate();

    const [sortKey, setSortKey] = useState(query.get('sortKey') || defaultSortKey);
    const [sortDirection, setSortDirection] = useState(query.get('sortDirection') || 'desc');
    const [filters, setFilters] = useState(filterOptions);

    const sortIcon = (key) => {
        if (key !== sortKey) {
            return null;
        }

        return (
            <span className="inline-block align-bottom text-teal-700">
                {sortDirection === 'desc' ? <ChevronDown /> : <ChevronUp />}
            </span>
        );
    };

    const performSort = (a, b) => {
        const customSortFunction = customSortFunctions[sortKey];

        if (customSortFunction) {
            return sortDirection === 'desc' ? customSortFunction(a, b) : customSortFunction(b, a);
        }

        if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'desc' ? -1 : 1;
        }

        return sortDirection === 'desc' ? 1 : -1;
    };

    const changeSortKey = (key) => {
        if (key !== sortKey) {
            setSortKey(key);
            query.set('sortKey', key);
        } else {
            const direction = sortDirection === 'desc' ? 'asc' : 'desc';
            setSortDirection(direction);
            query.set('sortDirection', direction);
        }
        navigate(`${location.pathname}?${query.toString()}`);
    };

    const onChangeFilter = (changedFilter) => {
        const nextFilters = [...filters];
        const filter =
            nextFilters.find((filter) => filter.name === changedFilter.name) || changedFilter;
        filter.selection = changedFilter.selection;
        setFilters(nextFilters);
    };

    const filteredUsers = filters.reduce(
        (result, filter) => filter.apply(result, filter.selection),
        users,
    );

    return (
        <>
            {filterOptions && <Filters filters={filterOptions} onChange={onChangeFilter} />}
            <p className="mb-4">
                Matching users: <strong>{filteredUsers.length}</strong>
            </p>
            <div className="w-full overflow-auto">
                {filteredUsers.length > 0 && (
                    <table className="text-sm w-full">
                        <thead>
                            <tr className="border-b border-teal-600">
                                {columnHeadings.map(([title, key]) => (
                                    <th key={key}>
                                        <button
                                            className="text-left px-3 py-2 w-full hover:bg-teal-100 hover:text-teal-700"
                                            type="button"
                                            onClick={() => changeSortKey(key)}
                                        >
                                            {title} {sortIcon(key)}
                                        </button>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.sort(performSort).map((user) => (
                                <tr
                                    id={user[identifier]}
                                    key={user[identifier]}
                                    className="even:bg-gray-100 hover:bg-teal-100 cursor-pointer"
                                    onClick={handleUserClick}
                                >
                                    {columnHeadings.map(([title, key, modifier]) => (
                                        <td className="px-3 py-2" key={key}>
                                            {modifier ? modifier(user[key]) : user[key]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
};

export default UserList;

import React from 'react';
import { Formik, Form } from 'formik';

import Input from '../shared/forms/Input';
import SubmitButton from '../shared/forms/SubmitButton';

const SignIn = ({ didError, onSignIn, onToggleForgotPassword }) => {
    const initialValues = {
        userName: '',
        password: '',
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSignIn}>
            {({ isSubmitting }) => {
                return (
                    <Form className="text-left">
                        <p className="text-xl font-light text-teal-600 border-b border-teal-600 pt-2 mb-4">
                            Please Sign In
                        </p>

                        <Input
                            name="userName"
                            label="User Name"
                            placeholder="e.g. jane.smith"
                            disabled={isSubmitting}
                        />
                        <Input
                            name="password"
                            type="password"
                            label="Password"
                            autocomplete="new-password"
                            disabled={isSubmitting}
                        />

                        <p className="text-sm mb-6">
                            Have you{' '}
                            <button
                                type="button"
                                className="text-teal-600 underline"
                                onClick={onToggleForgotPassword}
                            >
                                forgotten your password
                            </button>
                            ?
                        </p>

                        {didError && (
                            <p className="text-sm text-center text-red-700 mb-4">
                                We were unable to sign you in.
                                <br />
                                Please double check your details and try again.
                            </p>
                        )}

                        <SubmitButton isSubmitting={isSubmitting} text="Sign In" />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SignIn;

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import UserList from './UserList';
import Loader from '../../shared/Loader';
import Tag from '../../shared/Tag';
import api from '../../../utils/api';
import { prettyDate } from '../../../utils/shared';
import { passwordStatusColors } from '../../../utils/colors';

const renderPasswordStatusTab = (status) => (
    <Tag color={passwordStatusColors[status]} snug>
        {status}
    </Tag>
);

const getOrganisations = (profile) => {
    if (!profile) return null;

    const organisations = profile.organisations
        ? Object.keys(profile.organisations)
        : [profile.organisation];

    return (
        <>
            {organisations.map((organisation) => (
                <Tag snug prettify={false} key={organisation}>
                    {organisation}
                </Tag>
            ))}
        </>
    );
};

const columnHeadings = [
    ['User name', 'userId'],
    ['Full name', 'userName'],
    ['Organisation(s)', 'profile', getOrganisations],
    ['Registered', 'createdAt', prettyDate],
    ['Password status', 'status', renderPasswordStatusTab],
];

const filterOptions = [
    {
        name: 'userId',
        label: 'User name',
        apply: (users, searchString = []) =>
            searchString.length > 0
                ? users.filter((user) =>
                      user.userId.toLowerCase().includes(searchString[0].toLowerCase()),
                  )
                : users,
    },
    {
        name: 'userName',
        label: 'Full name',
        apply: (users, searchString = []) =>
            searchString.length > 0
                ? users.filter((user) =>
                      user.userName?.toLowerCase().includes(searchString[0].toLowerCase()),
                  )
                : users,
    },
    {
        name: 'organisations',
        label: 'Organisations',
        options: [],
        apply: (users, organisations = []) =>
            organisations.length > 0
                ? users.filter((user) =>
                      Object.keys(user.profile?.organisations || {}).some((organisation) =>
                          organisations.includes(organisation),
                      ),
                  )
                : users,
    },
];

const UsersByType = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const getUsers = useCallback(async () => {
        setIsLoading(true);

        try {
            const usersResponse = await api.getUsers();
            setUsers(usersResponse);
            filterOptions.find((filter) => filter.name === 'organisations').options =
                usersResponse.reduce((result, user) => {
                    const userOrganisations = Object.keys(user.profile?.organisations || {});
                    userOrganisations.forEach((organisation) => {
                        if (!result.includes(organisation)) {
                            result.push(organisation);
                        }
                    });
                    return result;
                }, []);
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleUserClick = (event) => {
        navigate(`${location.pathname}/userId/${event.currentTarget.id}`);
    };

    return (
        <section className="p-6 h-full">
            <h1 className="mb-4 text-blue-800 text-3xl font-thin">Unified login users</h1>
            {isLoading ? (
                <div className="text-teal-600 flex justify-center my-5">
                    <Loader size={10} />
                </div>
            ) : (
                <UserList
                    users={users}
                    filterOptions={filterOptions}
                    columnHeadings={columnHeadings}
                    defaultSortKey="userId"
                    identifier="userId"
                    handleUserClick={handleUserClick}
                />
            )}
        </section>
    );
};

export default UsersByType;

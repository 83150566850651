import React, { useState } from 'react';
import { Check, ExclamationCircle } from 'heroicons-react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';

import Loader from '../../shared/Loader';
import api from '../../../utils/api';

const UserDetails = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
    });

    const [errors, setErrors] = useState({});
    const [creatingUser, setCreatingUser] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().required('Email is required').email('Enter a valid email address'),
        phoneNumber: Yup.string()
            .required('Phone number is required')
            .test(
                'phone-number-format',
                'Invalid phone number, please provide a valid mobile number including country code',
                (value) => {
                    return isValidPhoneNumber(value);
                },
            ),
    });

    const convertValidationErrors = (validationError) => {
        const errorsObject = {};

        if (validationError && validationError.inner) {
            validationError.inner.forEach((error) => {
                const { path, message } = error;

                if (errorsObject[path]) {
                    errorsObject[path].push(message);
                } else {
                    errorsObject[path] = [message];
                }
            });
        }

        return errorsObject;
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const trimmedValue = name === 'name' ? value : value.replace(/\s/g, '');
        setFormData({
            ...formData,
            [name]: trimmedValue,
        });

        try {
            await validationSchema.validateAt(name, { [name]: trimmedValue });
            setErrors({
                ...errors,
                [name]: [],
            });
        } catch (error) {
            setErrors({
                ...errors,
                [name]: [error.message],
            });
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            await validationSchema.validate(formData, { abortEarly: false });
        } catch (errors) {
            setErrors(convertValidationErrors(errors));
            return;
        }
        setCreatingUser(true);
        try {
            const response = await api.createUser({
                ...formData,
                roles: [],
            });
            navigate(`/users/userId/${response.userId}`);
        } catch (error) {
            console.error(error);
            setErrorMessage(error.response?.data?.error || 'Something went wrong.');
        }
        setCreatingUser(false);
    };

    return (
        <section className="p-6 h-full">
            <h1 className="mb-4 text-blue-800 text-3xl font-thin">Create user</h1>
            <form onSubmit={onSubmit}>
                <label className="mb-4 block text-sm">
                    <span className="mb-1 block">Name (required)</span>
                    <input
                        className="rounded border border-gray-400 py-2 px-3 w-full md:w-1/2"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter name..."
                    />
                    {errors.name &&
                        errors.name.map((e, i) => (
                            <p className="text-sm text-red-700 mt-1" key={i}>
                                {e}
                            </p>
                        ))}
                </label>
                <label className="mb-4 block text-sm">
                    <span className="mb-1 block">Email address (required)</span>
                    <input
                        className="rounded border border-gray-400 py-2 px-3 w-full md:w-1/2"
                        name="email"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email address..."
                    />
                    {errors.email &&
                        errors.email.map((e, i) => (
                            <p className="text-sm text-red-700 mt-1" key={i}>
                                {e}
                            </p>
                        ))}
                </label>
                <label className="mb-4 block text-sm">
                    <span className="mb-1 block">Phone number (required)</span>
                    <input
                        className="rounded border border-gray-400 py-2 px-3 w-full md:w-1/2"
                        name="phoneNumber"
                        type="text"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder="Enter phone number..."
                    />
                    {errors.phoneNumber &&
                        errors.phoneNumber.map((e, i) => (
                            <p className="text-sm text-red-700 mt-1" key={i}>
                                {e}
                            </p>
                        ))}
                </label>
                <button
                    className="rounded py-2 px-4 mr-2 mb-2 bg-green-700 font-light text-white hover:bg-green-800 flex"
                    type="submit"
                >
                    {creatingUser ? (
                        <div className="-ml-1 mr-3">
                            <Loader />
                        </div>
                    ) : (
                        <Check className="mr-3" />
                    )}
                    Create
                </button>
            </form>
            {errorMessage && (
                <p className="text-sm text-red-700 mt-1 flex items-center">
                    <ExclamationCircle className="mr-1" />
                    {errorMessage}
                </p>
            )}
        </section>
    );
};

export default UserDetails;

import { Check, X, XCircle } from 'heroicons-react';
import React, { useState, useEffect } from 'react';

const Filter = (props) => {
    const [open, setOpen] = useState(props.open);
    const [selection, setSelection] = useState(props.selection || []);

    const containerRef = React.createRef();
    const inputRef = React.createRef();

    const onClickOutside = (event) => {
        if (containerRef.current?.contains(event.target) === false) {
            setOpen(false);
        }
    };

    const onClickEscape = (event) => {
        if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    useEffect(() => {
        inputRef.current?.focus();
        document.addEventListener('click', onClickOutside);
        document.addEventListener('keydown', onClickEscape);

        return () => {
            document.removeEventListener('click', onClickOutside);
            document.addEventListener('keydown', onClickEscape);
        };
    });

    const { name, label, options, apply, onChange } = props;

    const onUpdateSelection = (toggledOption, checked) => {
        let nextSelection = [...selection];
        checked
            ? nextSelection.push(toggledOption)
            : (nextSelection = selection.filter((option) => option !== toggledOption));
        setSelection(nextSelection);
        onChange({ name, apply, selection: nextSelection });
    };

    const onSubmitSelection = (event) => {
        event.preventDefault();
        onChange({ name, apply, selection });
    };

    const onClearSelection = (event) => {
        event.stopPropagation();
        setSelection([]);
        onChange({ name, apply, selection: '' });
    };

    return (
        <div className="relative text-sm" ref={containerRef} key={name}>
            <button
                className="rounded py-1 px-2 mb-1 bg-gray-200 font-light hover:bg-gray-300 mr-2 flex items-center"
                onClick={() => setOpen(!open)}
            >
                <span>
                    {label}:{' '}
                    <strong>{props.selection?.[0] ? props.selection.join(', ') : 'all'}</strong>
                </span>
                {props.selection?.[0] && (
                    <span className="text-gray-500" onClick={onClearSelection}>
                        <XCircle className="ml-1" size={20} />
                    </span>
                )}
            </button>
            {open && (
                <div className="absolute rounded py-2 px-4 bg-white shadow">
                    {options ? (
                        options.map((option) => (
                            <label
                                className="flex items-center cursor-pointer mb-1 whitespace-no-wrap"
                                key={option}
                            >
                                <input
                                    className="cursor-pointer mr-2"
                                    type="checkbox"
                                    checked={selection?.includes(option) || false}
                                    onChange={(event) =>
                                        onUpdateSelection(option, event.target.checked)
                                    }
                                />{' '}
                                {option}
                            </label>
                        ))
                    ) : (
                        <form onSubmit={onSubmitSelection}>
                            <input
                                className="rounded border border-gray-400 py-1 px-2"
                                ref={inputRef}
                                type="text"
                                value={selection[0] || ''}
                                onChange={(event) => setSelection([event.target.value])}
                                placeholder="Enter search text..."
                            />
                            <div className="flex justify-end">
                                <button
                                    className="rounded py-1 px-2 mt-1 mr-1 bg-gray-200 font-light hover:bg-gray-300 flex text-sm items-center"
                                    type="button"
                                    onClick={onClearSelection}
                                >
                                    <X className="mr-1" size={20} />
                                    Clear
                                </button>
                                <button
                                    className="rounded py-1 px-2 mt-1 bg-teal-600 font-light text-white hover:bg-teal-700 flex text-sm items-center"
                                    type="submit"
                                >
                                    <Check className="mr-1" size={20} />
                                    Apply
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            )}
        </div>
    );
};

const Filters = ({ filters, onChange }) => {
    return (
        <div className="flex mb-4">
            {filters.map((filter) => (
                <Filter {...filter} onChange={onChange} key={filter.name} />
            ))}
        </div>
    );
};

export default Filters;
